import {useEffect, useState} from "react";
import './App.css';
import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2';
import axios from './api/axiosConfig';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function App() {
    const [value, setValue] = useState(new Date());
    const [date, setDate] = useState('2024-20-12');
    const [swalProps, setSwalProps] = useState({});
    const [data, setData]  = useState({
        'date': '2024-20-12',
        'schedules': [
            {"time": "08:00 - 09:00", "status" : 'available'},
            {"time": "09:00 - 10:00", "status" : 'available'},
            {"time": "10:00 - 11:00", "status" : 'available'},
            {"time": "11:00 - 12:00", "status" : 'available'},
            {"time": "12:00 - 13:00", "status" : 'available'},
            {"time": "13:00 - 14:00", "status" : 'available'},
            {"time": "14:00 - 15:00", "status" : 'available'},
            {"time": "15:00 - 16:00", "status" : 'available'},
            {"time": "16:00 - 17:00", "status" : 'available'}
        ]
    });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const date = moment().format('YYYY-MM-DD');
        setDate(date);
        fetchReservations(date);
    }, []);

    function changeDate() {
        let selectedDate = moment(date);
        selectedDate = selectedDate.add(1, 'days').format('YYYY-MM-DD')
        setDate(selectedDate);
        fetchReservations(selectedDate);
    }

    async function fetchReservations(date) {
        const res = await axios.get('api/reservations?date='+date);
        setData(res.data);
    }

    function handleClick(schedule){
        if(schedule.status === 'reserved') {
            return false;
        }
        setSwalProps({
            show: true,
            title: 'Reservar cita',
            text: `Ingrese su nombre para confirmar su cita: ${schedule.time}`,
            input: 'text',
            inputPlaceholder: 'Ingrese su nombre',
            inputAttributes: {
                'aria-label': 'Nombre'
            },
            showCancelButton: true,
            confirmButtonText: 'Reservar',
            cancelButtonText: 'Cancelar',
            preConfirm: (name) => {
                if (!name) {
                    Swal.showValidationMessage("Por favor ingrese un nombre antes de reservar.");
                }
            },
            didClose: () => {
                setSwalProps({
                    show: false,
                });
            },
            onConfirm: (result) => {
                Swal.fire("Reserva realizada correctamente", "Recuerda llegar 15 minutos antes", "success");
                setTimeout(() => {
                    setSwalProps({
                        show: false,
                    });
                }, 2000);
            }
        });
    }

    const onCalendarChange = (val) => {
        setValue(val);
        let date = moment(val);
        date = date.format('YYYY-MM-DD');
        fetchReservations(date);
        setDate(date);
        setShow(false);
    }

    const getTodayReservations = () => {
        let selectedDate = moment(new Date());
        selectedDate = selectedDate.format('YYYY-MM-DD')
        setDate(selectedDate);
        fetchReservations(selectedDate);
    }

    return (
        <main className="container-fluid mt-4">
            <div className="row text-center pb-3">
                <h1>Calendario de citas { date === moment().format('YYYY-MM-DD') ? 'hoy' : date === moment().add(1, 'days').format('YYYY-MM-DD') ? 'mañana' : moment(date).format('DD/MM/YYYY')}</h1>
                <div>
                    <span className="availableSpan"></span>&nbsp; Disponible
                    &nbsp; &nbsp;
                    <span className="reservedSpan"></span>&nbsp; Reservado
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-6 g-4">
                {
                    data.schedules.map((schedule, index) => {
                        return <div className="col" key={index} onClick={() => handleClick(schedule)}>
                            <div className={`card ${schedule.status}`}>
                                <div className="card-body">{schedule.time}</div>
                            </div>
                        </div>
                    })
                }
                <div className="col-md-2 text-center">
                    <h2>Controles</h2>
                    <div>
                        <button className="control mx-2 mb-2" onClick={handleShow}>
                            Seleccionar fecha &nbsp;
                            <i className="fa fa-calendar"></i>
                        </button>
                        {
                            (date !== moment().format('YYYY-MM-DD')) &&
                            <button className="control mx-2 mb-2" onClick={getTodayReservations}>
                                Hoy &nbsp;
                                <i className="fa fa-check"></i>
                            </button>
                        }
                        <button className="control mx-2 mb-2" onClick={changeDate}>
                            { date === moment().format('YYYY-MM-DD') ? 'Mañana' : 'Siguiente' } &nbsp;
                            <i className="fa fa-arrow-right"></i>
                        </button>
                    </div>
                </div>

                <Modal show={show} onHide={handleClose} className="calendar-modal" centered>
                    <Modal.Header closeButton>
                    <Modal.Title>Selecciona una fecha</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Calendar onChange={onCalendarChange} value={value} locale="es-ES" minDate={new Date()}/>
                    </Modal.Body>
                    {/*<Modal.Footer>*/}
                    {/*    <Button variant="secondary" onClick={handleClose}>*/}
                    {/*        Cerrar*/}
                    {/*    </Button>*/}
                    {/*    <Button variant="primary" onClick={handleClose}>*/}
                    {/*        Seleccionar*/}
                    {/*    </Button>*/}
                    {/*</Modal.Footer>*/}
                </Modal>

            </div>

            <SweetAlert2 {...swalProps} />
        </main>
    );
}

export default App;
